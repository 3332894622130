<template>
  <tr
    v-for="reason in getReasons.data"
    :key="reason.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
      <p>{{ reason.id }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ reason.reason }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ convertedTime(reason.created_at) }}</p>
    </td>
    <td class="p-3 px-5">
      <button
        type="button"
        class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        @click="deleteReason(reason.id)"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  emits: ["delete"],
  methods: {
    deleteReason(reasonID) {
      this.$emit("delete", reasonID);
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  computed: {
    getReasons() {
      return this.$store.getters["admin/getReasons"];
    },
  },
};
</script>
