<template>
  <div class="shadow-lg rounded-2xl w-36 p-4 m-4 bg-white h-40">
    <div class="flex items-center">
      <span class="material-icons"> {{ icon }} </span>
      <p class="text-md text-grey ml-2">
        {{ cardTitle }}
      </p>
    </div>
    <div class="flex flex-col justify-start">
      <p class="text-grey text-4xl text-left  font-bold my-4">
        {{ cardValue }}
      </p>
      <div class="relative w-28 h-2 bg-gray-200 rounded">
        <div class="absolute top-0 h-2  left-0 rounded bg-green w-2/3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: {
      type: String,
      default: "Empty Card",
    },
    icon: {
      type: String,
      default: "help_outline",
    },
    cardValue: {
      type: Number,
      default: 0,
    },
  },
};
</script>
