<template>
  <tr
    v-for="room in getRooms.data"
    :key="room.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
      <p>{{ room.name }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ room.practice_id }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ convertedTime(room.created_at) }}</p>
    </td>
    <td class="p-3 px-5">
      <button
        type="button"
        class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        @click="deleteRoom(room.id)"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  emits: ["delete"],
  methods: {
    splitText(data) {
      const item = data.split("/");
      return item[item.length - 1];
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    deleteRoom(roomID) {
      this.$emit("delete", roomID);
    },
  },
  computed: {
    getRooms() {
      return this.$store.getters["admin/getRooms"];
    },
  },
};
</script>
