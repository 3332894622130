<template>
  <tr class="border-b">
    <th
      class="text-left p-3 px-5"
      v-for="(header, index) in headers"
      :key="index"
    >
      {{ header }}
    </th>
  </tr>
</template>

<script>
export default {
  inject: ["headers"],
  data() {
    return {};
  },
};
</script>
