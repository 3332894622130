<template>
  <tr
    v-for="practice in getPractices.data"
    :key="practice.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
      <p>{{ practice.practice_name }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ practice.id }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ convertedTime(practice.created_at) }}</p>
    </td>
    <td class="p-3 px-5">
      <span v-for="policy in practice.policies" :key="policy.id">
        <p>
          {{ policy.name }}
        </p>
      </span>
    </td>
    <td class="p-3 px-5">
      <button
        type="button"
        class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        @click="deletePractice(practice.id)"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  emits: ["delete"],
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    deletePractice(practiceID) {
      this.$emit("delete", practiceID);
    },
  },
  computed: {
    getPractices() {
      return this.$store.getters["admin/getPractices"];
    },
  },
};
</script>
