<template>
  <div class="bg-white rounded-lg shadow w-8/12 md:w-5/12 m-2">
    <div class="px-4 py-8">
      <span class="block w-full rounded-md shadow-sm" v-if="errorMessage">
        <span
          type="button"
          class="py-2 px-4  bg-red text-white w-full text-center text-base shadow-md rounded-lg "
          @click="resolveFormSubmit"
        >
          {{ errorMessage }}
        </span>
      </span>
      <div class="relative mt-6">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300"></div>
        </div>
        <div class="relative flex justify-center text-sm leading-5">
          <span class="px-2 text-gray-500 bg-white">
            {{ title }}
          </span>
        </div>
      </div>
      <form @submit.prevent="resolveFormSubmit">
        <div class="mt-6">
          <div class="w-full space-y-6">
            <div
              class="w-full"
              v-for="(input, keyName, index) in inputs"
              :key="index"
            >
              <div class="relative">
                <input
                  :type="input.type"
                  class=" rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                  :placeholder="input.placeholder"
                  v-model="localInputs[input.fieldName]"
                />
              </div>
            </div>
            <slot></slot>
            <div>
              <span class="block w-full rounded-md shadow-sm">
                <button
                  type="button"
                  class="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  @click="resolveFormSubmit"
                >
                  Submit
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="px-4 py-6 border-t-2 border-gray-200 bg-gray-50 sm:px-10">
      <p class="text-xs leading-5 text-gray-500">
        {{ instructions }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["resolve-form-submit"],
  props: {
    title: {
      type: String,
      required: false,
    },
    instructions: {
      type: String,
      required: true,
    },
    inputs: {
      type: Array,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      localInputs: {},
    };
  },
  methods: {
    resolveFormSubmit() {
      this.$emit("resolve-form-submit", this.localInputs);
      this.localInputs = {};
    },
  },
};
</script>
