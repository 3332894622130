<template>
  <tr
    v-for="role in getRoles.data"
    :key="role.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
      <p>{{ role.name }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ role.guard_name }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ convertedTime(role.created_at) }}</p>
    </td>
    <td class="p-3 px-5">
      <span v-for="user in role.users" :key="user.id">
        <p>
          {{ user.name }}
        </p>
      </span>
    </td>
    <td class="p-3 px-5">
      <button
        type="button"
        class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        @click="deleteRole(role.id)"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  emits: ["delete"],
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    deleteRole(roleID) {
      this.$emit("delete", roleID);
    },
  },
  computed: {
    getRoles() {
      return this.$store.getters["admin/getRoles"];
    },
  },
};
</script>
