<template>
  <!-- <p class="flex justify-end p-5 text-grey" v-show="props.hasRecords">
    {{ props.count }}
  </p> -->
  <div class="pb-5 flex justify-center text-grey">
    <nav class="flex items-center">
      <ul v-show="props.showPagination" :class="props.theme.list" class="flex">
        <!-- <li
          v-if="props.hasEdgeNav"
          :class="props.theme.firstPage"
          @click="props.setFirstPage"
        >
          <button type="button" v-bind="props.firstPageProps">
            {{ props.texts.first }}
          </button>
        </li>

        <li
          v-if="props.hasChunksNav"
          :class="props.theme.prevChunk"
          @click="props.setPrevChunk"
        >
          <button type="button" v-bind="props.prevChunkProps">
            {{ props.texts.prevChunk }}
          </button>
        </li> -->

        <li
          class="w-full p-4 border-t border-b border-l cursor-pointer text-base rounded-l-xl text-grey bg-white hover:bg-lightGrey"
          @click="props.setPrevPage"
        >
          <button type="button" v-bind="props.prevProps">
            {{ props.texts.prevPage }}
          </button>
        </li>

        <li
          v-for="page in props.pages"
          :key="page"
          :class="props.pageClasses(page)"
          class="w-full items-center flex px-4 border-t border border-b cursor-pointer bg-white hover:bg-lightGrey"
          v-on="props.pageEvents(page)"
        >
          <button type="button" v-bind="props.aProps" :class="props.theme.link">
            {{ page }}
          </button>
        </li>

        <li
          :class="props.theme.next"
          @click="props.setNextPage"
          class="w-full p-4 border-t border-b border-r text-base cursor-pointer rounded-r-xl text-grey bg-white hover:bg-lightGrey"
        >
          <button type="button" v-bind="props.nextProps">
            {{ props.texts.nextPage }}
          </button>
        </li>
        <!-- <li
          v-if="props.hasChunksNav"
          :class="props.theme.nextChunk"
          @click="props.setNextChunk"
        >
          <button type="button" v-bind="props.nextChunkProps">
            {{ props.texts.nextChunk }}
          </button>
        </li>

        <li
          v-if="props.hasEdgeNav"
          :class="props.theme.lastPage"
          @click="props.setLastPage"
        >
          <button type="button" v-bind="props.lastPageProps">
            {{ props.texts.last }}
          </button>
        </li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MyPagination",
  props: {
    props: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.active {
  color: #2997ff;
}
</style>
