<template>
  <div class="bg-lighGrey shadow">
    <div class="p-4 flex content-center">
      <p class="text-3xl">
        {{ title }}
      </p>
    </div>
    <div class="px-3 py-4 flex content-center overflow-x-hidden">
      <table class="w-full text-md bg-white shadow-md rounded mb-4">
        <tbody>
          <table-header></table-header>
          <component :is="selectedComponent" @delete="deletRow"></component>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Permissions from "./permissionsTableItem.vue";
import Policies from "./policiesTableItem.vue";
import Practice from "./practicesTableItem.vue";
import Reasons from "./reasonsTableItem.vue";
import Roles from "./rolesTableItem.vue";
import Rooms from "./roomsTableItem.vue";
import tableHeader from "./tableHeader.vue";
import Users from "./usersTableItem.vue";
export default {
  inheritAttrs: false,
  components: {
    tableHeader,
    Practice,
    Users,
    Policies,
    Roles,
    Permissions,
    Rooms,
    Reasons,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["delete"],
  data() {
    return {
      selectedComponent: this.title,
    };
  },
  methods: {
    deletRow(id) {
      this.$emit("delete", id);
    },
  },
};
</script>
