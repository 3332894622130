<template>
  <tr
    v-for="policy in getPolicies"
    :key="policy.id"
    class="border-b hover:bg-orange-100 bg-gray-100"
  >
    <td class="p-3 px-5">
      <p>{{ policy.name }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ policy.practice_id }}</p>
    </td>
    <td class="p-3 px-5">
      <p>{{ splitText(policy.attachment) }}</p>
    </td>
    <td class="p-3 px-5">
      <p>
        {{ policy.signatures.length }}
      </p>
    </td>
    <td class="p-3 px-5">
      <button
        type="button"
        class="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
        @click="deletePolicy(policy.id)"
      >
        Delete
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  emits: ["delete"],
  methods: {
    splitText(data) {
      const item = data.split("/");
      return item[item.length - 1];
    },
    deletePolicy(policyID) {
      this.$emit("delete", policyID);
    },
  },
  computed: {
    getPolicies() {
      return this.$store.getters["admin/getPolicies"];
    },
  },
};
</script>
