<template>
  <div class="bg-white pb-5 flex justify-center text-grey">
    <nav class="flex items-center">
      <ul class="flex animate-pulse">
        <li
          class="w-full p-4 border-t border-b border-l cursor-pointer text-base rounded-l-xl text-lightGrey"
        >
          <button type="button" disabled>
            &#8592;
          </button>
        </li>

        <li
          class="w-full p-4 border-t border-b border-r text-base cursor-pointer rounded-r-xl text-lightGrey "
        >
          <button type="button" disabled>
            &#8594;
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "blankPaginate",
};
</script>

<style>
.active {
  color: #2997ff;
}
</style>
